import { format } from 'date-fns'
import { es, enUS } from 'date-fns/locale'
import type { Locale } from 'date-fns'

export function date(value: string | number | Date, desiredFormat ?: string, locale?: Locale): string {
  const _date = new Date(value)
  if (!desiredFormat) desiredFormat = 'PP'
  if (!locale) locale = getProfileLocale()
  return format(_date, desiredFormat, { locale: locale })
}

function getProfileLocale() : Locale {
  switch (window.profile.languageLocalisation) {
    case 'es': return es
    case 'en': return enUS;
    default: return es;
  }
}